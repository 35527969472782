h1,
h2,
h3,
h4,
h5,
h6 {
	color: #2E3192;
	text-transform: uppercase;
}

h1 {
	font: 900 2.4rem 'Lato';		
}

h2 {
	font: 800 4rem 'Lato';
}

h3 {
	font: bold 2rem 'Lato';
}

h4 {
	font: 800 1.8rem 'Lato';
	line-height: inherit;
}

h5 {
	font: bold 1.4rem 'Lato';
}

h6 {
	font: 800 1.2rem 'Lato';
}

p,
ul, 
ol {
	font: 14px 'myriad';
}

/*
b, strong {
	
}*/

.menu__info {
	font: 12px 'Lato';
	color: #fff;
}

.menu__info:hover {
	cursor: pointer;
}

.banner__titulo {
	font: bold 6.8rem 'Lato';
	color: #fff;
}

.banner__subtitulo {
	font: 5.6rem 'black_diamonds';
}

.secondary {
	color: #695433;
}

.secondary-dark-bg {
	color: #d2ac67;
}
